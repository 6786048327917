export default [
  {
    type: 'section',
    title: 'Informasi Produk',
    description: 'Isi informasi produk dengan lengkap untuk tarik lebih banyak pembeli.',
  },
  {
    key: 'name',
    label: 'Nama produk',
    required: true,
  },
  {
    key: 'description',
    label: 'Deskripsi produk',
    type: 'textarea',
    rows: 8,
    required: true,
  },
  {
    key: 'category_id',
    label: 'Kategori',
    type: 'select',
    actionSearch: 'category/search',
    required: true,
    initialOptions: true,
  },
  {
    key: 'images',
    label: 'Foto produk',
    type: 'multiple-image',
    required: true,
  },
  {
    key: 'is_active',
    label: 'Status',
    type: 'radio',
    required: true,
    options: [
      { value: true, text: 'Aktif' },
      { value: false, text: 'Tidak aktif' },
    ],
  },
  // {
  //   type: 'variant1',
  //   skus: 'sku',
  //   variant: 'variants',
  //   subVariant: 'sub_variants',
  // },
  {
    key: 'variants',
    subVariant: 'sub_variants',
    skus: 'skus',
    type: 'variant',
  },
  {
    key: 'sub_variants',
    variant: 'variants',
    skus: 'skus',
    type: 'sub-variant',
  },
  {
    key: 'skus',
    variant: 'variants',
    subVariant: 'sub_variants',
    type: 'sku',
  },
  {
    type: 'section',
    title: 'Harga',
    visibility: {
      callback: data => !data.find(item => item.key === 'variants').value,
    },
  },
  {
    key: 'skus[0].price',
    label: 'Harga',
    type: 'number',
    prepend: 'Rp',
    min: 1,
    required: true,
    visibility: {
      callback: data => !data.find(item => item.key === 'variants').value,
    },
  },
  {
    key: 'skus[0].compare_price',
    label: 'Harga coret',
    type: 'number',
    prepend: 'Rp',
    min: 1,
    notes: [
      'Harga awal produk sebelum promosi.',
      'Isi dengan cara memindahkan nilai kolom <b>\'Harga\'</b> ke <b>\'Harga coret\'</b>. Lalu isi kolom <b>\'Harga\'</b> dengan harga promosi.',
    ],
    visibility: {
      callback: data => !data.find(item => item.key === 'variants').value,
    },
  },
  {
    key: 'skus[0].min_purchase',
    label: 'Minimum Pemesanan',
    type: 'number',
    min: 1,
    notes: 'Atur jumlah minimum yang harus dibeli untuk produk ini.',
    visibility: {
      callback: data => !data.find(item => item.key === 'variants').value,
    },
  },
  {
    key: 'skus[0].max_purchase',
    label: 'Maksimum Pemesanan',
    type: 'number',
    notes: 'Atur jumlah maksimum yang dapat dibeli untuk produk ini.',
    visibility: {
      callback: data => !data.find(item => item.key === 'variants').value,
    },
  },
  {
    key: 'skus[0].amount_multiple',
    label: 'Kelipatan Pemesanan',
    type: 'number',
    notes: 'Atur jumlah kelipatan kuantitas yang dapat dibeli untuk produk ini.',
    visibility: {
      callback: data => !data.find(item => item.key === 'variants').value,
    },
  },
  {
    type: 'section',
    title: 'Pengelolaan Produk',
    visibility: {
      callback: data => !data.find(item => item.key === 'variants').value,
    },
  },
  {
    key: 'skus[0].stock',
    label: 'Stok produk',
    type: 'number',
    min: 0,
    required: true,
    visibility: {
      callback: data => !data.find(item => item.key === 'variants').value,
    },
  },
  {
    key: 'skus[0].sku_number',
    label: 'SKU (Stock Keeping Unit)',
    notes: 'Gunakan kode unik SKU jika kamu ingin menandai produkmu.',
    visibility: {
      callback: data => !data.find(item => item.key === 'variants').value,
    },
  },
  {
    key: 'skus[0].weight',
    label: 'Berat produk',
    type: 'number',
    append: 'gram',
    min: 1,
    required: true,
    notes: [
      'Masukkan berat dengan menimbang produk <b>setelah dikemas</b>.',
      'Perhatikan dengan baik berat produk agar tidak terjadi selisih data dengan pihak kurir.',
    ],
    visibility: {
      callback: data => !data.find(item => item.key === 'variants').value,
    },
  },
]
